@import './theme3/admin-select-button';
@import './theme3/candidate-profile-delete-modal';
@import './theme3/approve-modal';
@import './theme3/snackbar';
@import './theme3/manual-action-dialog';
@import './theme3/admin-metrics-page';
@import './mixins';
@import './_configurationStyles';

.admin {
  @import './theme3/variables';
  @import './theme3/side-page-component';
  @import './theme3/feature-dashboard';
  @import './theme3/feature-onboarding';
  @import './theme3/mail-history';
  @import './theme3/feature-candidates-profile';
  @import './theme3/multiple-select';
  @import './theme3/app-vertical-stepper-ver1';
  @import './theme3/typography';
  @import './theme3/candidates-onboarding-view';
  @import './theme3/documents-and-data-upload';
  @import './theme3/personal-details';
  @import './theme3/modal-template';
  @import './theme3/approve-modal';
  @import './theme3/_upload-documents';
  @import './theme3/_oo-feature-dashboard';
  @import './theme3/admin-autocomplete';
  @import './theme3/feature-admin-import';
  @import './theme3/feature-mail-overview';
  @import './theme3/feature-candidate-profile';

  // dropdown-filters clientMultipleSelect style
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;

  .popover-c {
    .MuiList-padding {
      padding: 0;
      width: 304px;
    }

    .MuiButton-outlinedSizeSmall {
      padding: 7px 10px;
      min-width: 72px;
    }

    .MuiButton-containedSizeSmall {
      padding: 8px 10px;
      min-width: 72px;
    }

    .MuiDivider-root {
      height: 1px;
      background-color: rgba(150, 140, 131, 0.6);
    }

    .MuiListItemText-root {
      .MuiTypography-body1 {
        @include nunito-regular(16px, 1.5);
      }
    }

    .MuiListItem-gutters {
      box-shadow: inset 0 -1px 0 0 rgba(150, 140, 131, 0.2);
    }

    .selected {
      background-color: $dust;
      -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

      .MuiTypography-body1 {
        @include nunito-bold(16px, 1.5);
        -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }

    .selected {
      .MuiSvgIcon-root {
        animation-name: showItem;
        animation-duration: 0.3s;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
    }

    @keyframes showItem {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
.MuiAutocomplete-root > input {
  border: none !important;
}
